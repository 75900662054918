@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Dense";
  src: url("./fonts/Dense-Regular.otf") format("otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "resist-sans";
  src: url("./fonts/resistsans-txtlight.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

html, body {
  font-family: "resist-sans", sans-serif;
}